<template>
  <div>
    <panel />
  </div>
</template>

<script>
export default {
  name: "Authentication",

  metaInfo: {
    title: "Connexion",
    src: require("@/assets/theme/contact.jpg")
  },

  data: () => ({
    partners: [
      {
        name: "",
        ico: ""
      }
    ]
  }),

  props: {
    id: {
      type: String,
      default: "AdminCenter"
    }
  },

  components: {
    panel: () => import("@/components/LoginPanel")
  }
};
</script>
